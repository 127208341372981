
.flex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Loading Spinner */

.loader {
    width: 48px;
    height: 48px;
    border: 5px dotted #c895a3;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
}

.padding{
    padding: 3vh;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}